//
//
//
//
//
//

const slug = 'index'

export default {
  async asyncData({ app, store, $content, params }) {
    const page = await $content('pages', slug).fetch()
    store.commit('pages/set', page)
    return {
      page
    }
  }
}
